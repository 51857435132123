import React from "react";
import "../../style/App.css";
import "../../style/GlobalVars.css";
import Home from "../Home/Home";
import Content from "../../components/Content/Content";

function Startseite() {
  return (
    <Content>
      <Home />
    </Content>
  );
}

export default Startseite;
