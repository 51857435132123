import React from "react";
import { FaCheckCircle, FaClock, FaTools } from "react-icons/fa";
import styles from "./Home.module.css";
import Content from "../../components/Content/Content";
import { Link } from "react-router-dom";


function Home() {
  return (
    <Content>
      <div className={styles.container}>
        <h1 className={styles.heading}>Willkommen bei Margraf</h1>
        <p className={styles.subtitle}>Ihr zuverlässiger Partner für hochwertige Bauarbeiten und Landschaftsgestaltung</p>
        <div className={styles.features}>
          <div className={styles.feature}>
            <FaCheckCircle className={styles.featureIcon} />
            <h2 className={styles.featureTitle}>Herausragende Qualität</h2>
            <p className={styles.featureDescription}>Wir garantieren Ihnen die beste Qualität für Ihre Projekte. Unsere Experten sorgen dafür, dass jedes Detail perfekt umgesetzt wird.</p>
          </div>
          <div className={styles.feature}>
            <FaClock className={styles.featureIcon} />
            <h2 className={styles.featureTitle}>Termintreue</h2>
            <p className={styles.featureDescription}>Wir halten unsere Versprechen und liefern pünktlich. Ihr Projekt wird termingerecht abgeschlossen, ohne Kompromisse bei der Qualität einzugehen.</p>
          </div>
          <div className={styles.feature}>
            <FaTools className={styles.featureIcon} />
            <h2 className={styles.featureTitle}>Expertenwissen</h2>
            <p className={styles.featureDescription}>Mit unserem langjährigen Know-how stehen wir Ihnen bei jedem Schritt zur Seite. Vertrauen Sie auf unsere Erfahrung für erfolgreiche Ergebnisse.</p>
          </div>
        </div>
        <Link to={"/portfolio"}>
          <button className={styles.ctaButton}>Leistung</button>
        </Link>
        <Link to={"/kontakt"}>
           <button className={styles.ctaButton}>Kontakt</button>
        </Link>
      </div>
    </Content>
  );
}

export default Home;
