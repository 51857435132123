import React from "react";
import styles from "./Error.module.css"

const Error = () => {
    return (
      <div className={styles.error}>
        <h1>Du bist hier Falsch!</h1>
      </div>
    );
  }
  
  export default Error;
  