// Kontakt.js

import React from "react";
import Content from "../../components/Content/Content";
import styles from "./Kontakt.module.css";

const Kontakt = () => {
  return (
    <Content>
      <div className={styles.title}>
        <h1>Kontaktieren Sie uns</h1>
      </div>
      <div className={styles.contactInfo}>
        <div className={styles.contactItem}>
          <h3>Adresse:</h3>
          <p>Dominik Margraf</p>
          <p>Grüner Weg 1</p>
          <p>34399 Wesertal</p>
        </div>
        <div className={styles.contactItem}>
          <h3>Telefon:</h3>
          <p>01722854612</p>
        </div>
        <div className={styles.contactItem}>
          <h3>E-Mail:</h3>
          <p>info@baumargraf.de</p>
        </div>
        <div className={styles.mapContainer}>
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2546.118949442122!2d9.34566931535024!3d51.68087152965547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b84d4b73c68c8d%3A0x7e1f16f3c01215fc!2sGr%C3%BCner%20Weg%201%2C%2034399%20Wesertal!5e0!3m2!1sen!2sde!4v1634799718928!5m2!1sen!2sde"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </Content>
  );
};

export default Kontakt;
