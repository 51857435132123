import React from "react";
import Content from "../../components/Content/Content";
import styles from "./Impressum.module.css";

const Impressum = () => {
  return (
    <Content>
      <h1 className={styles.ueberschrift}>Impressum</h1>
      <hr className={styles.impline} />
      <div className={styles.impressum}>
        <div className={styles.section}>
          <h2>Impressum</h2>
          <hr />
          <div className={styles.content}>
            <div>
              <h3>Firma</h3>
              <p>Garten- und Landschaftsbau Dominik Margraf</p>
            </div>
            <div className={styles.links}>
              <h3>Telefon</h3>
              <p><a href="tel:+491722854612">+49 172 285 4612</a></p>
            </div>
            <div className={styles.links}>
              <h3>Adresse</h3>
              <p><a href="https://maps.google.com/maps?q=Grüner+Weg+1,+34399+Wesertal" target="_blank" rel="noreferrer">Grüner Weg 1, 34399 Wesertal</a></p>
            </div>
            <div className={styles.links}>
              <h3>E-Mail</h3>
              <p><a href="mailto:info@baumargraf.de">info@baumargraf.de</a></p>
            </div>
          </div>
        </div>
        {/* <div className={styles.section}>
          <h2>Handelsregister</h2>
          <hr />
          <div className={styles.content}>
            <div>
              <h3>Registergericht</h3>
              <p>Amtsgericht Musterstadt</p>
            </div>
            <div>
              <h3>Registernummer</h3>
              <p>HRB 12345</p>
            </div>
            <div>
              <h3>Umsatzsteuer-Identifikationsnummer</h3>
              <p>DE123456789</p>
            </div>
          </div>
        </div> */}
        <div className={styles.section}>
          <h2>Urheberrecht und Marken</h2>
          <hr />
          <div className={styles.content}>
            <div>
              <h3>Alle auf dieser Website verwendeten Inhalten</h3>
              <p>sind Eigentum von BauMargraf.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.platform}>
        <h2>Plattform der EU-Kommission zur Online-Streitbeilegung</h2>
        <p>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit. Die Plattform finden Sie unter:
          <a href="https://ec.europa.eu/consumers/odr">Link zur OS-Plattform</a>
        </p>
      </div>
    </Content>
  );
};

export default Impressum;
