import React from "react";
import styles from "./Header.module.css";
// import Loginbar from "../Loginbar/Loginbar";
// import img from "../../assets/img/Logo.png"


const Header = () =>  {
    return (
        <>
            <div className={styles["container-fluid"]}>
                {/* <Loginbar /> */}
                <div className={styles["logo"]}>
                    <img src={"/logo.png"} alt="Galabau Logo" className={styles["logoSize"]} />
                </div>
            </div>
        </>
    );
};

export default Header;
