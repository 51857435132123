import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faFilm, faTv, faBars } from "@fortawesome/free-solid-svg-icons";
import styles from "./Navbar2.module.css";

const Navbar2 = () => {
  const baseData = [
    {
      icon: faHome,
      name: "Startseite",
      link: "/",
    },
    {
      icon: faFilm,
      name: "Portfolio",
      link: "/portfolio",
    },
    {
      icon: faTv,
      name: "Kontakt",
      link: "/kontakt",
    },
  ];

  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDropdownClose = () => {
    setShowDropdown(false);
  };



  const data = [...baseData];

  return (
    <>
          <div className={styles.normalNavbar}>
        {data.map((item, index) => (
          <Link key={index} to={item.link} className={styles.linkTo}>
            <button className={styles.button}>
              <FontAwesomeIcon icon={item.icon} className={styles.customIcon} />
              <span className={styles.iconText}>{item.name}</span>
            </button>
          </Link>
        ))}
      </div>
      <div className={styles.navbar}>
        <button className={styles.hamburgerButton} onClick={handleDropdownToggle}>
          <FontAwesomeIcon icon={faBars} className={styles.customIcon} />
        </button>
        <div className={`${styles.dropdownContent} ${showDropdown ? styles.showDropdown : ""}`}>
          {data.map((item, index) => (
            <Link key={index} to={item.link} className={styles.linkTo} onClick={handleDropdownClose}>
              <button className={styles.button}>
                <FontAwesomeIcon icon={item.icon} className={styles.customIcon} />
                <span className={styles.iconText}>{item.name}</span>
              </button>
            </Link>
          ))}
        </div>
      </div>

    </>
  );
};

export default Navbar2;