import { Link } from "react-router-dom";
import React from "react";
import styles from "./Footer.module.css";

function Footer() {
  const currentYear = new Date().getFullYear();

  return ( 
    <footer className={styles.footer}>
      <nav>
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <Link to="/" className={styles.navLink}>Startseite</Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/portfolio" className={styles.navLink}>Portfolio</Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/kontakt" className={styles.navLink}>Kontakt</Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/impressum" className={styles.navLink}>Impressum</Link>
          </li>
        </ul>
      </nav>
      <p className={styles.footerText}>&copy; {currentYear} Kevin Losem</p>
    </footer>
  );
}

export default Footer;
