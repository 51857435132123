import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Startseite from "./Pages/Startseite/Startseite";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Kontakt from "./Pages/Kontakt/Kontakt";
import Error from "./Pages/Error/Error";
import Impressum from "./Pages/Impressum/Impressum";
import Footer from "./components/Footer/Footer";
import Navbar2 from "./components/Navbar2/Navbar2";
import Header from './components/Header/Header';


const App = () => {
  

  return (
    <>
      <BrowserRouter>
            <Header />
            <Navbar2 />
            <Routes>
              <Route path="/" element={<Startseite />} exact />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/kontakt" element={<Kontakt />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="*" element={<Error />} />
            </Routes>
            <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
