import React, { useState } from "react";
import Content from "../../components/Content/Content";
import PortfolioImages from "../../assets/img/Portfolio/PortfolioImages"; // Importiere die Bilder aus der portfolioImages-Datei
import styles from "./Carousel.module.css";

const PortfolioCarousel = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <Content>
      <div>
        <h1 className={styles.ueberschrift}>Entdecken Sie Ihr Neues Projekt!</h1>
        <div>
          <hr className={styles.introH1}></hr>
        </div>
      </div>
      <div className={styles.gridContainer}>
        {PortfolioImages.map((imageSrc, index) => (
          <div key={index} className={styles.gridItem}>
            <img
              src={imageSrc}
              alt={`${index}`}
              className={styles.thumbnail}
              onClick={() => openImage(imageSrc)}
            />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className={styles.modalBackdrop} onClick={closeImage}>
          <div className={styles.modal}>
            <span className={styles.closeButton} onClick={closeImage}>×</span>
            <img src={selectedImage} alt="Selected" className={styles.modalImage} />
          </div>
        </div>
      )}
    </Content>
  );
};

export default PortfolioCarousel;
